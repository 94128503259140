import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message, Upload } from 'antd';

import {
  deleteRule,
  getDetailCompanyRules,
  getListCompanyRules,
  updateRule,
  uploadRuleDoc,
} from "../api/app-service/companyPolicyService";
import { Button, Col, Input, Row, Select, Spin, Tooltip, TreeSelect, Typography } from "antd";
import { ArrowLeftOutlined, CloseCircleOutlined, CloseOutlined, InboxOutlined, LinkOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { TextEditorFullTKG } from "../components/TextEditorFullTKG";
import Swal from "sweetalert2";
import Context from "../utils/context";
import { renderTreeSelect, transformToNested } from "../utils/functions";
import ContentComponent from "../components/ContentComponent";
import { getListUser } from "../api/app-service/Service";
const { Title, Paragraph } = Typography;
const { Dragger } = Upload;

const EditPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [contentDetail, setContentDetail] = useState("");
  const [title, setTitle] = useState("");
  const [dataDetail, setDataDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [listUser, setListUser] = useState([]);
  const [userAccess, setUserAccess] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const context = useContext(Context);
  const inputFile = useRef(null);
  const handleClickImport = () => {
    inputFile.current.click();
  };
  const handleDeleteItem = (path) => {
    Swal.fire({
      icon: "question",
      title: "Are you sure want to delete ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleDeleteFile(path);
      }
    });
  };

  const handleDeleteFile = (path) => {
    setDocuments(
      documents.filter((item) => item.path !== path),
    );
  };

  const handleGetFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setLoadingUpload(true);
    const fileImport = e.target.files[0];
    if (fileImport) {
      uploadRuleDoc(fileImport, {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      }).then((response) => {
        // console.log('response doc', response);
        if (response?.data?.meta?.status === 200) {
          setDocuments([
            ...documents,
            response?.data?.response,
          ]);
          setLoadingUpload(false);
        } else {
          Swal.fire({
            text: "Upload error",
            icon: "error",
            confirmButtonText: "Confirm",
          });
          setLoadingUpload(false);
        }
      });
    }
  };
  const fetchListUser = async () => {
    await getListUser({
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    }).then(res => {
      if (res?.status === 200) {
        const formatData = res?.data?.response?.map(item => ({
          label: `${item?.first_name} ${item?.surname}`,
          value: item?.id
        }))
        setListUser(formatData);
      }
    })
  }

  useEffect(() => {
    fetchListUser()
  }, [])

  const onChange = (newValue) => {
    setSelectedID(newValue);
  };
  const getDetailRule = async (id) => {
    const res = await getDetailCompanyRules(id, { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }).then((res) => {
      if (res?.status === 200) {
        const formatData = {
          ...res?.data?.response,
          access_users: res?.data?.response?.access_users ? JSON.parse(res?.data?.response?.access_users?.replace(/'/g, '"')) : []
        }
        setDataDetail(formatData);
        setUserAccess(res?.data?.response?.access_users ? JSON.parse(res?.data?.response?.access_users?.replace(/'/g, '"')) : []);
        setContentDetail(res?.data?.response?.content);
        setTitle(res?.data?.response?.title);
        setSelectedID(res?.data?.response?.parent_id)
        setDocuments(res?.data?.response?.documents)
      }
    });
    return res;
  };

  useEffect(() => {
    if (params?.id) {
      setLoading(true);
      getDetailRule(params?.id).then((res) => setLoading(false));
    }
  }, [params]);

  const handleChangeUserView = (value) => {
    setUserAccess(value);
  };
  const handleEdit = async () => {
    if (title && contentDetail) {
      const payload = {
        ...dataDetail,
        title: title,
        content: contentDetail,
        documents: documents?.length > 0 ? documents.map((doc) => doc.path) : [],
        parent_id: selectedID || null,
        access_users: userAccess || []
      };
      setLoading(true);
      await updateRule(params?.id, payload, { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }).then(async (res) => {
        if (res?.status === 200) {
          await getListCompanyRules({ Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }).then((res) => {
            if (res?.data?.meta?.status === 200) {
              const dataRes = res?.data?.response;
              const dataList = transformToNested(dataRes);
              context.setListMenu(dataList);
            } else {
              console.log("error fetch list");
            }
          });
          navigate(`/view/${params?.id}`);
        }
      });
      setLoading(false);
    } else {
      Swal.fire({
        text: title ? "Content cannot be null!" : "Title cannot be null!",
        icon: "warning",
        confirmButtonText: "Confirm",
      });
    }
  };

  const handleDelete = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        await deleteRule(params?.id, { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }).then(async (res) => {
          if (res?.status === 200) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            await getListCompanyRules({ Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }).then((res) => {
              if (res?.data?.meta?.status === 200) {
                const dataRes = res?.data?.response;
                const dataList = transformToNested(dataRes);
                context.setListMenu(dataList);
              } else {
                console.log("error fetch list");
              }
            });
            navigate(`/`);
          }
        });
        setLoading(false);
      }
    });
  };

  const props = {
    name: 'document',
    multiple: true,
    action: 'https://api-company-rule.tweetworldtravel.com/api/rule-fines/upload-document',
    headers: {
      authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },

    onChange(info) {
      setLoading(true);
      const { status, response } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setDocuments([
          ...documents,
          response?.response,
        ]);
        setLoading(false);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };


  return (
    <Spin spinning={loading}>
      <Row gutter={12} style={{ marginBottom: "12px" }}>
        <Col span={24}>
          <div className="site-layout__content__button-top">
            <Button
              type="primary"
              className="site-layout__content__button-delete"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>

        </Col>
        <Col span={16}>
          <Paragraph style={{
            margin: "0 5px",
          }}>Title</Paragraph>
          <Input
            className="site-layout__content__input"
            placeholder="Title here"
            size="large"
            value={title}
            required
            onChange={(e) => setTitle(e?.target?.value)}
          />
        </Col>
        <Col span={8}>
          <Paragraph style={{
            margin: "0 5px",
          }}>Sub-Menu (Skip if root menu)</Paragraph>
          <TreeSelect
            showSearch
            size="large"
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            value={context.listMenu?.length > 0 ? selectedID : ""}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
            placeholder="Select Parent"
            allowClear
            // treeExpandedKeys={[expandedKeys]}
            // onTreeExpand={((key) => {console.log(key)} )}
            onChange={onChange}
            treeData={renderTreeSelect(context.listMenu || [])}
          />
        </Col>
        <Col span={24}>
          <Paragraph style={{
            margin: "0 5px",
          }}>User Access</Paragraph>
          <Select
            mode="multiple"
            size="large"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Please select"
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            value={userAccess}
            onChange={handleChangeUserView}
            options={listUser}
          />
        </Col>

        <Col span={24}>
          {/* <Paragraph style={{
            margin: "10px 0 5px 5px",
          }}>Documents</Paragraph> */}
          {/* <Button
             type="primary"
            onClick={handleClickImport}
            icon={<UploadOutlined />}
            loading={loadingUpload}
             style={{background: '#fff',borderColor:"#0288d1",color:"#0288d1"}}
             ghost
          >
            Upload document
          </Button>
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            accept=".jpg,.jpeg,.png,.gif,.xlsx,.docs,.pdf,.docx,.ppt,.pptx"
            ref={inputFile}
            onChange={handleGetFile}
          /> */}

          {/* {documents?.length > 0 && (
            <div style={{ margin: "10px 0" }}>
              {documents.map((doc, index) => {
                // console.log(doc);
                return (
                  <div key={index} className="import-item">
                    <a href={doc?.preview} target="_blank">
                      {doc.path}
                    </a>

                    <Tooltip title="Delete" className="import-item-del" onClick={() => {
                      handleDeleteItem(doc.path);
                    }}>
                      <Button type="primary" size="small" shape="circle" icon={<CloseOutlined />} danger />
                    </Tooltip>
                  </div>
                )
              }
              )}
            </div>
          )} */}
        </Col>
      </Row>
      <Row gutter={24} style={{marginBottom:"20px"}}>
        <Col span={24}>
          <Paragraph style={{
            margin: "10px 0 5px 5px",
          }}>Documents</Paragraph>
        </Col>
        <Col span={8}>
          <Dragger {...props}
            fileList={document.fileList}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">Upload Documents</p>
            <p className="ant-upload-hint" style={{ margin: 0 }}>
              Click or drag file to this area to upload
            </p>
          </Dragger>
        </Col>
        <Col span={16}>
          {documents?.length > 0 && (
            <div style={{ margin: "10px 0" }}>
              {documents.map((doc, index) => {
                // console.log(doc);
                return (
                  <div key={index} className="import-item">
                    <a href={doc?.preview} target="_blank">
                      {doc.path}
                    </a>

                    <Tooltip title="Delete" className="import-item-del" onClick={() => {
                      handleDeleteItem(doc.path);
                    }}>
                      <Button type="primary" size="small" shape="circle" icon={<CloseOutlined />} danger />
                    </Tooltip>
                  </div>
                )
              }
              )}
            </div>
          )}
        </Col>
      </Row>


      <TextEditorFullTKG data={contentDetail} setData={setContentDetail} />
      <div style={{ display: "flex" }}>
        <div className="site-layout__content__button-div">
          <Button
            // loading={deleting}
            type="primary"
            className="site-layout__content__button-back"
            onClick={() => navigate(`/view/${params?.id}`)}
            icon={<ArrowLeftOutlined />}
          >
            Back To View
          </Button>
        </div>
        <div style={{ flex: 1 }}></div>
        <div className="site-layout__content__button-div">
          <Button
            type="primary"
            className="site-layout__content__button-save"
            onClick={handleEdit}
          >
            Save
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default EditPage;
