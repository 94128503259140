
import moment from 'moment';
import { getListUser } from '../api/app-service/Service';
import { useEffect, useState } from 'react';
import { Button, Divider, Space } from 'antd';
import { FileOutlined } from '@ant-design/icons';

const Information = ({
  value,
}) => {
  const [listUser, setListUser] = useState([]);
  // console.log(value.documents);

  const fetchListUser = async () => {
    await getListUser({
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    }).then(res => {
      if (res?.status === 200) {
        const formatData = res?.data?.response?.map(item => ({
          name: `${item?.first_name} ${item?.surname}`,
          id: item?.id
        }))
        setListUser(formatData);
      }
    })
  }

  useEffect(() => {
    fetchListUser()
  }, [])
  return (
    <>

      <div className="container site-layout__content__addition-block">
        <h3 className="site-layout__content__addition-block__label">
          Additional Information <br />
          <p className="site-layout__content__addition-block__label__footer">
            __ _
          </p>
        </h3>
        <p>
          <span className="site-layout__content__addition-block__title">
            Release Date:
          </span>
          {moment(value?.created_at).format(
            'DD/MM/YYYY hh:mm ',
          )}
        </p>
        <p>
          <span className="site-layout__content__addition-block__title">
            Release Region:
          </span>{' '}
          {value?.release_region}
        </p>
        <p>
          <span className="site-layout__content__addition-block__title">
            Authorised by:
          </span>{' '}
          {value?.created_by?.first_name}{' '}
          {value?.created_by?.surname}
        </p>
        <p>
          <span className="site-layout__content__addition-block__title">
            User access:
          </span>{' '}
          {
            value?.access_users?.length === 0 ? <>All employees</> : <ul>
              {listUser?.length > 0 && value?.access_users?.map(item => {
                const data = listUser?.find(em => em?.id === item)

                return <li key={item}>{data?.name}</li>
              }
              )}
            </ul>
          }

        </p>
      </div>

      <div className="container  site-layout__content__addition-block">
        <p>
          <span className="site-layout__content__addition-block__title">
            Updated Date:
          </span>{' '}
          {moment(value?.updated_at).format(
            'DD/MM/YYYY hh:mm ',
          )}
        </p>
        <p>
          <span className="site-layout__content__addition-block__title">
            Updated By:
          </span>{' '}
          {value?.updated_by?.first_name}{' '}
          {value?.updated_by?.surname}
        </p>
      </div>
      {
        value?.documents && Object.keys(value?.documents).length > 0 ? 
        <div className="container  site-layout__content__addition-block">
          <span className="site-layout__content__addition-block__title">
            Documents:
          </span>
          <Divider style={{ margin: "10px 0" }} />
          <Space direction="vertical" size={'small'}>
            {value?.documents.map((doc, index) => {
              return (
               <Space><FileOutlined style={{ fontSize: '18px', color: '#1677ff' }}/> <a key={index} href={doc?.preview} target="_blank" >{doc?.path.slice(21)}</a></Space> 
              )
            }
            )}
          </Space>
        </div> : ""
      }

    </>
  );
};

export default Information;
