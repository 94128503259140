import { Button, Col, Row, Spin, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDetailCompanyRules,
  sendEmailToGroup,
  uploadRuleDoc,
} from "../api/app-service/companyPolicyService";
import Information from "../components/information";
import Swal from "sweetalert2";
import { CloseCircleOutlined, LinkOutlined } from "@ant-design/icons";
const { Text, Title } = Typography;
const ViewPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [dataDetail, setDataDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const [importing, setImporting] = useState(false);



  const user = JSON.parse(sessionStorage.getItem("user"));
  const getDetailRule = async (id) => {
    const res = await getDetailCompanyRules(id, {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    }).then((res) => {
      if (res?.status === 200) {
        const formatData = {
          ...res?.data?.response,
          access_users: res?.data?.response?.access_users
            ? JSON.parse(res?.data?.response?.access_users?.replace(/'/g, '"'))
            : [],
        };
        setDataDetail(formatData);
      }
    });
    return res;
  };

  useEffect(() => {
    if (params?.id) {
      setLoading(true);
      getDetailRule(params?.id).then((res) => setLoading(false));
    }
  }, [params]);

  const SendEmail = () => {
    const payload = {
      id: dataDetail.id,
      title: dataDetail.title,
      content: dataDetail.content,
    };
    Swal.fire({
      icon: "info",
      title: "Send email ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingSendMail(true)
        const res = await sendEmailToGroup(payload, {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        });
        if (res.data.meta.status === 200) {
          setLoadingSendMail(false)
          Swal.fire({
            icon: 'success',
            title: 'Successfully',
            text: 'Send email successfully!!',
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Send email fail!!',
          })
        }

      }
    });
  };

  const renderUserAccess = (dataDetail, user) => {
    if (user?.role_id === 1) {
      return true;
    } else {
      if (dataDetail?.access_users?.length === 0) {
        return true;
      } else if (dataDetail?.access_users?.includes(user?.id)) {
        return true;
      } else {
        return false;
      }
    }
  };


  return (
    <>
      {loading ? (
        //   <div
        //  style={{textAlign: "center"}}
        //   >
        <Spin style={{
          width: "100%",
          height: `calc(100vh - 100px)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // textAlign: "center",
        }} />
        //  </div>
      ) : (
        <>
          {user && dataDetail && renderUserAccess(dataDetail, user) ? (
            <Row align="space-around" wrap={true} gutter={[16, 16]}>
              <Col
                span={18}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                className="container"
              >
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: dataDetail?.content,
                  }}
                ></div>
              </Col>

              <Col span={6}>
                <div style={{ position: "sticky", top: "100px" }}>
                  <Information value={dataDetail} />
                  <div
                    wrap
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      type="primary"
                      className="site-layout__content__button-save"
                      onClick={() => {
                        navigate(`/edit/${params?.id}`);
                      }}
                    >
                      Edit
                    </Button>

                    <Button
                      disabled={loadingSendMail}
                      loading={loadingSendMail}
                      type="primary"

                      onClick={() => SendEmail()}
                    >
                      Send email
                    </Button>
                   </div>
                </div>
              </Col>
            </Row>
          ) : (
            <div>
              <Title level={3} type="danger">
                You are not allowed to view!
              </Title>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ViewPage;
