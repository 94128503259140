import { Layout } from 'antd';
import Logo from '../assets/logos/default-avatar.jpg';
import { MenuOutlined } from '@ant-design/icons';
import { encode } from 'js-base64';
import { Link } from 'react-router-dom';

const { Header } = Layout;
const LayoutHeader = ({ openDrawer, visible }) => {
  return (
    <Header className="site-layout__header">
      <div className="site-layout__header__logo">
        <div>
          <Link to={process.env.REACT_APP_DOMAIN_SSO}>
            <img alt="Logo" src={Logo} width="75px" />
          </Link>
        </div>
      </div>
      <div className="site-layout__header__title">
        <span className="text-style-shadow h1">
          Company Policy & Info
        </span>
      </div>
      <div style={{ flexGrow: 1 }}></div>
      <div className="site-layout__header__name">
        <div>
          <span className="text-style-shadow h4">
            Welcome
          </span>
          <br />
          <span className="text-style-shadow h1">
            {
              JSON.parse(sessionStorage.getItem('user'))
                ?.first_name
            }
          </span>
        </div>
      </div>
      <div className="site-layout__header__menu">
        <MenuOutlined
          onClick={() => openDrawer(!visible)}
        />
      </div>
    </Header>
  );
};

export default LayoutHeader;
