import React, { useContext, useEffect, useState } from "react";
import { Button, Menu, Spin } from "antd";
import { getListCompanyRules } from "../api/app-service/companyPolicyService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Context from "../utils/context";
import { renderTreeMenu, transformToNested } from "../utils/functions";
const LayoutSider = () => {
  const navigate = useNavigate();
  const params = useParams()
  const [selectedKey, setSelectedKey] = useState();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const context = useContext(Context);
  const [openKeys, setOpenKeys] = useState();
  const onOpenChange = (keys) => {
    let latestOpenKey = keys[keys.length - 1];
    if (keys?.length < 2 ) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const fetchListRules = async () => {
    const res = await getListCompanyRules({Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}).then((res) => {
      if (res?.data?.meta?.status === 200) {
        let dataRes = []
        // console.log(user);
        if (user?.role_id === 1) {
          dataRes = res?.data?.response
        } else {
            dataRes = res?.data?.response?.filter(item => {
              const access_users = item?.access_users
              ? JSON.parse(item?.access_users?.replace(/'/g, '"'))
              : []
              return access_users?.length === 0 || access_users?.includes(user?.id)
            } )
        }

        const dataList = transformToNested(dataRes);
        context.setListMenu(dataList);
        const companyPolicyItem = dataRes?.find(item => item?.id?.toString() === "100014" )
        if(companyPolicyItem) {
          setSelectedKey(companyPolicyItem?.id?.toString());
          setOpenKeys([companyPolicyItem?.id?.toString()]);
        }

        return dataRes;
      } else {
        console.log("error fetch list");
      }
    });
    return res;
  };

  useEffect(() => {
    setLoading(true);
    fetchListRules().then((res) => setLoading(false));
  }, []);

  const handleSelectItems = ({
    item,
    key,
    keyPath,
    selectedKeys,
    domEvent,
  }) => {
    setSelectedKey(key);
    navigate(`/view/${key}`);
  };


  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh-100px)",
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          <div className={"sider__menu-side-bar"}>
            <Menu
              onClick={handleSelectItems}
              defaultSelectedKeys={[selectedKey]}
              defaultOpenKeys={openKeys}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              selectedKeys={[params?.id]}
              mode="inline"
              items={renderTreeMenu(context.listMenu)}
            />
          </div>

          <br />
          {/* <div
            className="sider__button-create-rule"
            style={{
              margin: "10px",
            }}
          >
            <Button
              // loading={deleting}
              block
              type="primary"
              onClick={() => navigate(`/create`)}
              style={{fontWeight:600,backgroundColor:"#154d76"}}
            >
              Create
            </Button>
          </div> */}
        </>
      )}
    </>
  );
};

export default LayoutSider;
